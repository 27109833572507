<script setup lang="ts">
import dayjs from "dayjs";

import type { HomeBanners } from "@/types";

const router = useRouter();
const { t } = useT();
const { isDesktop } = useDevice();
const { open } = useAppModals();

defineProps<{ banner: HomeBanners[number] }>();

const {
	tournamentData,
	activeStatus,
	durationLeft,
	isSubscribed,
	loadingSubscribtions,
	entriesSum,
	coinsSum,
	handleSubscribe
} = useRace();

const textBtn = computed(() => {
	if (!activeStatus.value) {
		return t("More Info");
	}
	return isSubscribed.value ? t("Play now") : t("Join Now");
});

const handleSubscribeRace = () => {
	if (!activeStatus.value) {
		router.push("/races/");
		return;
	}
	handleSubscribe({
		id: tournamentData.value?.data?.id || 0,
		isSub: isSubscribed.value
	});
	open("LazyOModalRacesGames", { isRaceMode: true });
};
</script>

<template>
	<client-only>
		<MBanner
			:bg-img="banner.backgroundImage"
			:bg-img2x="banner.backgroundImage2x"
			:bg-color="banner.background"
			:image="banner.image"
			:image-mobile="banner.imageMobile"
			:second-image="banner.secondImage"
			:second-image-mobile="banner.secondImageMobile"
			:type="banner.type"
			@click="handleSubscribeRace"
		>
			<template #default>
				<AText variant="tulsa" class="race-banner-title" :modifiers="['medium']">
					{{ t("FunzCity Races") }}
				</AText>
			</template>
			<template #description>
				<div class="race-banner-prizepool">
					<MPrizeFund
						v-if="coinsSum"
						variant="coins"
						img="/nuxt-img/prizes/funcoins@2x.png"
						:icon-size="isDesktop ? 30 : 18"
					>
						<AText variant="taizhou" class="text-tlalnepantla race-banner-text" :modifiers="['bold']">
							{{ numberFormat(coinsSum) }}
						</AText>
					</MPrizeFund>
					<MPrizeFund
						v-if="entriesSum"
						variant="entries"
						img="/nuxt-img/prizes/citycoins@2x.png"
						:icon-size="isDesktop ? 30 : 18"
					>
						<AText variant="taizhou" class="text-tlalnepantla race-banner-text" :modifiers="['bold']">
							{{ numberFormat(entriesSum) }}
						</AText>
					</MPrizeFund>
					<AText variant="taizhou" class="text-tlalnepantla race-banner-text" :modifiers="['bold']">
						{{ t("Prize Pool") }}
					</AText>
				</div>
				<AText variant="taizhou" class="text-tlalnepantla race-banner-text" :modifiers="['bold']">
					{{ t("Join & Play & Win Prizes!") }}
				</AText>
				<MRaceTimer
					variant="topeka"
					timeVariant="tlalpan"
					:iconSize="16"
					contentColor="var(--coro)"
					:text="activeStatus ? t('Finish in:') : t('Starts at')"
					:date="activeStatus ? durationLeft : dayjs(tournamentData?.data?.start).format('h:mm A')"
				/>
			</template>
			<template #actions>
				<AButton variant="primary" class="button" size="xl">
					<AAnimationSpin v-if="loadingSubscribtions">
						<NuxtIcon name="24/loader" filled />
					</AAnimationSpin>
					<AText v-else variant="tuzla" class="text-tlalnepantla" :modifiers="['semibold']">{{ textBtn }}</AText>
				</AButton>
			</template>
		</MBanner>
	</client-only>
</template>

<style lang="scss" scoped>
.race {
	&-banner-timer {
		display: flex;
		align-items: center;
		gap: gutter(0.5);
		margin-top: gutter(2);

		@include media-breakpoint-down(md) {
			margin-top: gutter(1.5);
		}
	}

	&-banner-prizepool {
		display: flex;
		align-items: center;
		gap: gutter(2);

		&:deep(.prize .icon svg) {
			@include media-breakpoint-down(md) {
				font-size: 27px;
			}
		}

		@include media-breakpoint-down(md) {
			gap: gutter(1);
		}
	}

	&-banner-text {
		text-shadow: 0px 4px 4px var(--coronel);

		@include media-breakpoint-down(md) {
			font-size: 20px;
		}
	}

	&-banner-title {
		text-shadow: 0px 4px 4px var(--coronel);

		@include media-breakpoint-down(md) {
			font-size: 14px;
		}
	}
}

:deep {
	.app-banner {
		&__content {
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			padding: gutter(4);

			@include media-breakpoint-down(md) {
				padding: gutter(2);
			}
		}

		&__desc {
			align-items: flex-start;

			@include media-breakpoint-down(md) {
				gap: 0;
			}
		}

		&__actions {
			margin-top: gutter(2);
			width: 280px;

			@include media-breakpoint-down(md) {
				margin-top: gutter(1.5);
				width: 133px;
			}

			.button {
				width: 100%;

				@include media-breakpoint-down(md) {
					height: 40px;
					border-radius: 8px;
				}
			}
		}
	}
}
</style>
